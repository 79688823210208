import styled from 'styled-components';


export const QuickLinksWrapper  = styled.div`
    h3 {
        font-weight: 700;
        font-size: 2em;
        border-bottom: 1px solid white;
    }
    
    ul {
    
        list-style: none;
    
        li {
            color: red;
            width: 100%;
            text-align: center;       
 
            a {
                font-weight: bold;
                text-align: center;
                text-decoration: none;
                padding: 10px;
                
                :hover {
                    color: black;
                }
            }
            
        }
    }
    
    @media screen (max-width:670px) {
        width: 100%;
        float:none;
    }
`;