import styled from 'styled-components';
import {Link} from 'react-router-dom';
import {FaFacebook, FaTwitter} from 'react-icons/fa';


export const SocialMediaFacebook = styled(FaFacebook)`
`;

export const SocialMediaTwitter = styled(FaTwitter)`
`;

export const SocialMediaLink = styled(Link)`
    padding: 10px;
`;

