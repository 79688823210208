import React from "react";
import {QuickLinksWrapper} from './QuickLinksElements';

const QuickLinks = () => {
    return (
        <QuickLinksWrapper className="footer-columns quick-links">
            <h3>Quick Links</h3>
            <ul>
                <li><a href="/">Home</a></li>
                <li><a href="/services">Services</a></li>
                <li><a href="/projects">Projects</a></li>
                <li><a href="/contact">Contact</a></li>
                <li><a href="/about-us">About Us</a></li>
            </ul>
        </QuickLinksWrapper>
    );
};

export default QuickLinks;