import React from 'react';

import { SidebarContainer, Icon, CloseIcon, SidebarMenu, SidebarLink, SidebarRoute, SideBtnWrap } from './SidebarElements';

const Sidebar = ({isOpen, toggle}) => {
    return (
        <SidebarContainer isOpen={isOpen} onClick={toggle}>
            <Icon>
                <CloseIcon/>
            </Icon>
            <SidebarMenu>
                <SidebarLink to='/services'>Services</SidebarLink>
                <SidebarLink to='/projects'>Projects</SidebarLink>
                <SidebarLink to='/contact'>Contact</SidebarLink>
                <SidebarLink to='/about-us'>About Us</SidebarLink>
            </SidebarMenu>
            <SideBtnWrap>
                <SidebarRoute href='tel:+16467243923'>Call Us</SidebarRoute>
            </SideBtnWrap>
        </SidebarContainer>
    );
};

export default Sidebar;