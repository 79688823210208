import React from "react";
import {SocialMediaFacebook, SocialMediaTwitter, SocialMediaLink} from "./SocialMediaElements";


const SocialMedia = () => {
    return (
        <div className="footer-columns social-media">
            <h3>Social Media Links</h3>
            <SocialMediaLink to=''><SocialMediaFacebook/></SocialMediaLink>
            <SocialMediaLink to=''><SocialMediaTwitter /></SocialMediaLink>
        </div>
    );
};

export default SocialMedia;
