import React, {Suspense} from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { GlobalStyle } from './globalStyles';
import Hero from './components/Hero';

function App() {
  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <GlobalStyle />
        <Hero />
      </Suspense>
    </Router>
  );
}

export default App;
