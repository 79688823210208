import React from 'react';
import {FooterWrapper, FooterLogo} from './FooterElements';
import QuickLinks from '../QuickLinks';
import SocialMedia from '../SocialMedia';

const Footer = () => {
    return (
        <FooterWrapper>
            <FooterLogo className="footer-columns logo">Logo Here</FooterLogo>
            <QuickLinks/>
            <SocialMedia/>
        </FooterWrapper>
    );
};

export default Footer;