import React, {useState, Suspense, lazy} from 'react';
import Navbar from "../Navbar";
import { HeroContainer, HeroContent } from './HeroElements';
import Sidebar from "../Sidebar";
import {Route} from "react-router";
import Footer from '../Footer';

const Home = lazy(() => import('../Home'));
const About = lazy(() => import('../About'));
const Services = lazy(() => import('../Services'));
const Projects = lazy(() => import('../Projects'));
const Contact = lazy(() => import('../Contact'));

const Hero = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    };

    return (
        <HeroContainer>
            <Navbar toggle={toggle} />
            <Sidebar isOpen={isOpen} toggle={toggle}/>
            <HeroContent>
                    <switch>
                        <Route path="/" exact component={Home} />
                        <Route path="/services" component={Services} />
                        <Route path="/projects" component={Projects} />
                        <Route path="/about-us" component={About} />
                        <Route path="/contact" component={Contact} />
                    </switch>
            </HeroContent>
            <Footer />
        </HeroContainer>

    );
};

export default Hero;
