import styled from 'styled-components';

export const FooterWrapper = styled.div`
    min-height: 300px;
    background: #9b896f;
    float: left;
    width: 100%;
    color: white;
    padding: 15px;
    
    @media screen and (max-width: 650px) {
        width: 100%;
    }
`;


export const FooterLogo = styled.div`
    
    @media screen and (max-width:767px) {
        display: none;
    }
`;