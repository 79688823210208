import {createGlobalStyle} from 'styled-components';

export const GlobalStyle = createGlobalStyle`
    * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        font-family: 'Kanit', sans-serif;
        color: white;
    }
    
    .mobile {
        display: none;
    }
    
    .input input, .text textarea {
        width: 100%;
        padding: 5px;
        color: black;
    }
    
    .text textarea {
        height: 200px;
    }
    
    .submit input {
        padding:10px;
        font-weight: bold;
        background: #ae5859e0;
        width: 200px;
        margin: 0 auto;
    }
    
    .footer-columns {
        width: 32%;
        float: left;
    }
    
     @media screen and (max-width: 767px) {
        background-color: #9b896f;

        .desktop {
            display: none;
        }
        
        .mobile {
            display: flex;
        }
        
        .footer-columns {
            float: none;
            width: 100%;
        }
    }
    
    
    
    
`;